import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import "./InputForm.css"; // Adjust import path according to your project structure

const DocumentTable = ({ content, no_dokumenP }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [tempContent, setTempContent] = useState({});
  const [selectedContent, setSelectedContent] = useState({});
  const [no_dokumen, setNo_dokumen] = useState(no_dokumenP);
  const [selectedSection, setSelectedSection] = useState("");
  const [isValidData, setIsValidData] = useState(true);

  const documentTypeRef = useRef("");

  useEffect(() => {
    setNo_dokumen(no_dokumenP);
  }, [no_dokumenP]);

  useEffect(() => {
    setSelectedDoc(content);
    setTempContent(content);
  }, [content]);

  useEffect(() => {
    try {
      const contentData = selectedDoc?.content ? JSON.parse(selectedDoc.content).sections : [];
      if (Array.isArray(contentData)) {
        setTableData(contentData);
        setIsValidData(true);
      } else {
        throw new Error("Invalid content data format");
      }
      setSelectedContent(selectedDoc.content);
      documentTypeRef.current = JSON.parse(selectedDoc.content)?.type || "Unknown";
    } catch (error) {
      console.error("Failed to parse document content:", error);
      setIsValidData(false);
    }
  }, [selectedDoc]);

  const updateSelectedDoc = (updatedContent) => {
    setSelectedDoc({
      ...selectedDoc,
      content: JSON.stringify({ type: documentTypeRef.current, sections: updatedContent }),
    });
  };

  const handleInputChange = (newValue, sectionIndex, categoryIndex, subCategoryIndex, itemIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[sectionIndex] &&
      updatedTableData[sectionIndex].categories &&
      updatedTableData[sectionIndex].categories[categoryIndex] &&
      updatedTableData[sectionIndex].categories[categoryIndex].subCategories &&
      updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex]
    ) {
      if (itemIndex !== undefined) {
        if (
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items &&
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items[itemIndex]
        ) {
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items[
            itemIndex
          ].value = newValue;
        }
      } else {
        updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].value = newValue;
      }

      setTableData(updatedTableData);
      updateSelectedDoc(updatedTableData);
    }
  };

  const handleKeteranganChange = (newKeterangan, sectionIndex, categoryIndex, subCategoryIndex, itemIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[sectionIndex] &&
      updatedTableData[sectionIndex].categories &&
      updatedTableData[sectionIndex].categories[categoryIndex] &&
      updatedTableData[sectionIndex].categories[categoryIndex].subCategories &&
      updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex]
    ) {
      if (itemIndex !== undefined) {
        if (
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items &&
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items[itemIndex]
        ) {
          updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].items[
            itemIndex
          ].keterangan = newKeterangan;
        }
      } else {
        updatedTableData[sectionIndex].categories[categoryIndex].subCategories[subCategoryIndex].keterangan = newKeterangan;
      }

      setTableData(updatedTableData);
      updateSelectedDoc(updatedTableData);
    }
  };

  const handleUpdateData = () => {
    if (tableData.length > 0) {
      const updatedContent = {
        ...JSON.parse(tempContent.content),
        sections: tableData,
      };

      console.log("Updated content:", updatedContent);
      console.log("No dokumen:", no_dokumen);

      const url = `https://amipartnership.com/api/document/updatecontent/${no_dokumen}`;

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: JSON.stringify(updatedContent) }), // Ensure content is stringified
      };

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update data");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Data updated successfully:", data);
          alert("Data updated successfully");
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          alert("Failed to update data");
        });
    } else {
      alert("No data to update");
    }
  };

  const handleSectionSelectChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const exportToExcel = () => {
    const excelData = [];

    tableData.forEach((section) => {
      section.categories.forEach((category) => {
        category.subCategories.forEach((subCategory) => {
          // For subCategory itself
          excelData.push({
            "Document Details": `${section.name} - ${category.name}`,
            "Value Yes": subCategory.value === "yes" ? "Ada" : "",
            "Value No": subCategory.value === "no" ? "Tidak Ada" : "",
            Notes: subCategory.keterangan || "",
          });

          // For each item within subCategory
          subCategory.items.forEach((item) => {
            excelData.push({
              "Document Details": `${item.name}`,
              "Value Yes": item.value === "yes" ? "Ada" : "",
              "Value No": item.value === "no" ? "Tidak Ada" : "",
              Notes: item.keterangan || "",
            });
          });
        });
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Define styles for headers and cells
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "CCCCCC" } }, // Light gray background
      border: { top: { style: "thin" }, bottom: { style: "thin" } }, // Thin border
    };

    const cellStyle = {
      border: { top: { style: "thin" }, bottom: { style: "thin" } }, // Thin border
    };

    // Apply styles to headers (first row)
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: range.s.r, c: C });
      worksheet[headerCell].s = headerStyle;
    }

    // Apply styles to cells (excluding headers)
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = XLSX.utils.encode_cell({ r: R, c: C });
        worksheet[cell].s = cellStyle;
      }
    }

    const workbook = XLSX.utils.book_new();
    const currentDate = new Date();

    // Get individual components of the date
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-indexed, so January is 0
    const day = currentDate.getDate();

    // Format date as YYYY-MM-DD
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Document Data");
    XLSX.writeFile(workbook, `RincianDokumen-${no_dokumen}-${formattedDate}.xlsx`);
  };

  const getDocumentType = () => {
    try {
      return documentTypeRef.current || "Unknown";
    } catch (e) {
      console.error("Failed to parse document content:", e);
      return "Unknown";
    }
  };

  const filteredTableData = selectedSection
    ? tableData.filter((section) => section.name === selectedSection)
    : tableData;

  return (
    <div className="table-container">
      <h4>Tipe Dokumen: {getDocumentType()}</h4>
      <div className="dropdown-container">
        <label htmlFor="sectionSelect">Select Section:</label>
        <select id="sectionSelect" value={selectedSection} onChange={handleSectionSelectChange}>
          <option value="">All Sections</option>
          {tableData.map((section, index) => (
            <option key={index} value={section.name}>
              {section.name}
            </option>
          ))}
        </select>
      </div>
      <button className="modal-button edit" onClick={handleUpdateData}>Update Data</button>
      <button className="modal-button edit" onClick={exportToExcel}>Export to Excel</button>
      {isValidData ? (
        <table className="table">
          <thead>
            <tr>
              <th>Document Details</th>
              <th>Yes</th>
              <th>No</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {filteredTableData.map((contentSection, sectionIndex) => {
              const originalSectionIndex = tableData.findIndex(section => section.name === contentSection.name);
              return (
                <React.Fragment key={sectionIndex}>
                  {contentSection.categories.map((category, categoryIndex) => (
                    <React.Fragment key={`${contentSection.name}-${category.name}`}>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>{`${contentSection.name} - ${category.name}`}</td>
                        <td colSpan="2"></td>
                        <td></td>
                      </tr>
                      {category.subCategories.map((subCategory, subCategoryIndex) => (
                        <React.Fragment key={`${contentSection.name}-${category.name}-${subCategory.name}`}>
                          <tr>
                            <td style={{ paddingLeft: "20px" }}>{`${subCategory.name}`}</td>
                            <td className="checklist">
                              <input
                                type="checkbox"
                                checked={subCategory.value === "yes"}
                                onChange={() => handleInputChange(subCategory.value === "yes" ? "no" : "yes", originalSectionIndex, categoryIndex, subCategoryIndex)}
                              />
                            </td>
                            <td className="checklist">
                              <input
                                type="checkbox"
                                checked={subCategory.value === "no"}
                                onChange={() => handleInputChange(subCategory.value === "no" ? "yes" : "no", originalSectionIndex, categoryIndex, subCategoryIndex)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={subCategory.keterangan}
                                onChange={(e) => handleKeteranganChange(e.target.value, originalSectionIndex, categoryIndex, subCategoryIndex)}
                              />
                            </td>
                          </tr>
                          {subCategory.items.map((item, itemIndex) => (
                            <tr key={`${contentSection.name}-${category.name}-${subCategory.name}-${item.name}`}>
                              <td style={{ paddingLeft: "40px" }}>{`- ${item.name}`}</td>
                              <td className="checklist">
                                <input
                                  type="checkbox"
                                  checked={item.value === "yes"}
                                  onChange={() => handleInputChange(item.value === "yes" ? "no" : "yes", originalSectionIndex, categoryIndex, subCategoryIndex, itemIndex)}
                                />
                              </td>
                              <td className="checklist">
                                <input
                                  type="checkbox"
                                  checked={item.value === "no"}
                                  onChange={() => handleInputChange(item.value === "no" ? "yes" : "no", originalSectionIndex, categoryIndex, subCategoryIndex, itemIndex)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.keterangan}
                                  onChange={(e) => handleKeteranganChange(e.target.value, originalSectionIndex, categoryIndex, subCategoryIndex, itemIndex)}
                                />
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>Data not valid</div>
      )}
    </div>
  );
};

export default DocumentTable;

import React, { useState } from "react";
import AssetTable from "./AssetTable";
import ExportExcel from "./ExportExcel"; // Assuming you have an ExportExcel component
import { useNavigate } from "react-router-dom";
import DBFetcher from "./databaseFetcher";

function Dashboard({ user }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [targetItems, setTargetItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0); // Add a refreshKey state
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCombinedDataFetch = (combinedRFIDs) => {
    setCurrentItems(combinedRFIDs);
  };

  const refreshData = () => {
    // Increment the refreshKey to force a re-render
    setRefreshKey((prevKey) => prevKey + 1);
    setTargetItems([]); // Optionally reset targetItems
    setCurrentItems([]); // Optionally reset currentItems
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowClick = (rfid) => {
    console.log(`Row clicked with RFID: ${rfid}`);
    // Uncomment and implement if you want to handle row clicks and fetch data:
    // fetch(`https://amipartnership.com/api/documents/${rfid}`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("Data fetched:", data);
    //     // Process the fetched data as needed
    //   })
    //   .catch((err) => console.error(err));
  };

  console.log("User data received in Dashboard:", user);

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Database View</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              
              <div className="header-container">
                {/* <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                /> */}
                {/* Uncomment and implement these buttons if needed:
                <i className="bx bx-plus" onClick={() => showModal("add")}></i>
                <ExportExcel />
                <i
                  className="bx bx-cloud-upload"
                  onClick={() => showModal("upload")}
                ></i> */}
              </div>
            </div>
            <DBFetcher
              key={`db-fetcher-${refreshKey}`} // Use refreshKey to force re-fetch
              returnType="table"
              onFetchTargetItems={setTargetItems}
              currentItems={currentItems}
              onRowClick={() => {}}
              user={user}
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Dashboard;

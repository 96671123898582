import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./DBFetcher.css";

const ApprovalFetcher = ({ user }) => {
  const [assets, setAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterKondisi, setFilterKondisi] = useState("");
  const [filterApprovalAction, setFilterApprovalAction] = useState("");
  const [filterApprovalStatus, setFilterApprovalStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const location = useLocation();

  useEffect(() => {
    fetchApproval();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchTerm, filterKondisi, filterApprovalAction, filterApprovalStatus, assets]);

  useEffect(() => {
    handleSortAssets();
  }, [sortConfig]);

  const fetchApproval = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/approval");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAssets(data.approvalAsset);
      setFilteredAssets(data.approvalAsset);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterKondisi = (event) => {
    setFilterKondisi(event.target.value);
  };

  const handleFilterApprovalAction = (event) => {
    setFilterApprovalAction(event.target.value);
  };

  const handleFilterApprovalStatus = (event) => {
    setFilterApprovalStatus(event.target.value);
  };

  const handleFilter = () => {
    let filteredData = assets;

    if (searchTerm) {
      filteredData = filteredData.filter((asset) =>
        Object.values(asset).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (filterKondisi) {
      filteredData = filteredData.filter(
        (asset) => asset.kondisi === filterKondisi
      );
    }

    if (filterApprovalAction) {
      filteredData = filteredData.filter(
        (asset) => asset.approval_action.toString() === filterApprovalAction
      );
    }

    if (filterApprovalStatus) {
      filteredData = filteredData.filter(
        (asset) => asset.approval_status.toString() === filterApprovalStatus
      );
    }

    setFilteredAssets(filteredData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    handleSortAssets(key, direction);
  };

  const handleSortAssets = () => {
    let sortedAssets = [...filteredAssets];

    if (sortConfig.key) {
      sortedAssets.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredAssets(sortedAssets);
  };

  const handleSelectRow = (id_approval) => {
    if (selectedRows.includes(id_approval)) {
      setSelectedRows(selectedRows.filter((id) => id !== id_approval));
    } else {
      setSelectedRows([...selectedRows, id_approval]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allApprovalIds = filteredAssets.map((asset) => asset.id_approval);
      setSelectedRows(allApprovalIds);
    } else {
      setSelectedRows([]);
    }
  };

  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const actionChanger = (action) => {
    if (action === 0) {
      return "Create";
    } else if (action === 1) {
      return "Update";
    } else if (action === 2) {
      return "Delete";
    }
    return `Action ${action} not recognized`;
  };

  const statusChanger = (action) => {
    if (action === 0) {
      return "Waiting for Approval";
    } else if (action === 1) {
      return "Approved";
    } else if (action === 2) {
      return "Denied";
    }
    return `Action ${action} not recognized`;
  };

  const handleApprove = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/approve", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: selectedRows }), // Send only ids array
      });
  
      if (!response.ok) {
        // Try to extract error message from response body
        const errorData = await response.json();
        const errorMessage = errorData?.error || "Approval failed due to unknown reasons.";
  
        // Check for specific errors, such as duplicate entries
        if (errorMessage.toLowerCase().includes("duplicate entry")) {
          throw new Error("Duplicate entry detected. Some assets have already been approved.");
        } else {
          throw new Error(errorMessage);
        }
      }
  
      alert("Assets Approved Successfully");
      fetchApproval(); // Refresh the list
      setSelectedRows([]); // Clear selected rows after approval
  
    } catch (error) {
      // Handle network errors or other unexpected issues
      if (error.name === "TypeError") {
        console.error("Network error or server unreachable:", error);
        alert("Network error: Unable to reach the server. Please try again later.");
      } else {
        console.error("Error approving assets:", error);
        alert(`Error: ${error.message}`); // Display error message from the server
      }
    }
  };
  

  const handleDeny = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/deny", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: selectedRows }), // Send only ids array
      });

      if (response.ok) {
        alert("Assets Denied Successfully");
        fetchApproval();
        setSelectedRows([]); // Clear selected rows after denial
      } else {
        throw new Error("Denying failed");
      }
    } catch (error) {
      console.error("Error denying assets:", error);
    }
  };

  return (
    <div className="table-container">
      <div className="filter-controls" style={{ float: 'left', width: '30%', display: 'flex' }}>
      <select onChange={handleFilterApprovalStatus} value={filterApprovalStatus} style={{ margin: '0 5% 0 0' }}>
          <option value="">All Status</option>
          <option value="0">Waiting for approval</option>
          <option value="1">Approved</option>
          <option value="2">Denied</option>
        </select>
        <select onChange={handleFilterKondisi} value={filterKondisi} style={{ margin: '0 5% 0 0' }}>
          <option value="">All Kondisi</option>
          <option value="Baik">Baik</option>
          <option value="Rusak">Rusak</option>
        </select>
        <select onChange={handleFilterApprovalAction} value={filterApprovalAction} style={{ margin: '0 5% 0 0' }}>
          <option value="">All Actions</option>
          <option value="0">Create</option>
          <option value="1">Update</option>
          <option value="2">Delete</option>
        </select>
      </div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        style={{ float: 'right', width: '20%', display: 'flex' }}
      />
      <table className="asset-table">
        <thead>
          <tr id="tb-header" style={{backgroundColor:"#abcaf5"}}>
            <th rowSpan="2">Select All
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th rowSpan="2" onClick={() => handleSort("id_approval")}>ID Approval</th>
            <th rowSpan="2" onClick={() => handleSort("id_asset")}>ID Asset</th> {/* New Column */}
            <th rowSpan="2" onClick={() => handleSort("approval_action")}>Approval Action</th>
            <th rowSpan="2" onClick={() => handleSort("approval_status")}>Approval Status</th> {/* New Column */}
            <th rowSpan="2" onClick={() => handleSort("nama_asset")}>Nama Asset</th>
            <th rowSpan="2" onClick={() => handleSort("no_rfid")}>No RFID</th>  {/* New Column */}
            <th rowSpan="2" onClick={() => handleSort("no_inventaris")}>No Inventaris</th>  {/* New Column */}
            <th rowSpan="2" onClick={() => handleSort("kode_asset")}>Kode Asset</th>
            <th rowSpan="2" onClick={() => handleSort("kondisi")}>Kondisi</th>
            <th rowSpan="2" onClick={() => handleSort("tanggal_perawatan")}>Tanggal Perawatan</th>
            <th rowSpan="2" onClick={() => handleSort("nama_pic")}>Nama PIC</th>
            <th rowSpan="2" onClick={() => handleSort("detail_pic")}>Detail PIC</th> {/* New Column */}
            <th rowSpan="2" onClick={() => handleSort("status_asset")}>Status Asset</th>
            <th rowSpan="2" onClick={() => handleSort("harga_perolehan")}>Harga Perolehan</th>
            <th rowSpan="2" onClick={() => handleSort("no_contract")}>No Contract</th>
            <th rowSpan="2" onClick={() => handleSort("keterangan")}>Keterangan</th>
            <th rowSpan="2" onClick={() => handleSort("keterangan")}>No Dokumen Referensi</th>
            <th colSpan="3">Lokasi</th>
            <th rowSpan="2" onClick={() => handleSort("username")}>Username</th>
            <th rowSpan="2" onClick={() => handleSort("approval_deletion_reason")}>Approval Deletion Reason</th> {/* New Column */}
          </tr>
          <tr style={{backgroundColor:"#abcaf5"}}>
            <th onClick={() => handleSort("gedung")}>Gedung</th>
            <th onClick={() => handleSort("lantai")}>Lantai</th>
            <th onClick={() => handleSort("ruangan")}>Ruangan</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.map((asset) => (
            <tr key={asset.id_approval}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(asset.id_approval)}
                  onChange={() => handleSelectRow(asset.id_approval)}
                />
              </td>
              <td>{asset.id_approval}</td>
              <td>{asset.id_asset}</td>  {/* Displaying new column */}
              <td>{actionChanger(asset.approval_action)}</td>
              <td>{statusChanger(asset.approval_status)}</td>  {/* Displaying new column */}
              <td>{asset.nama_asset}</td>
              <td>{asset.no_rfid}</td>  {/* Displaying new column */}
              <td>{asset.no_inventaris}</td>  {/* Displaying new column */}
              <td>{asset.kode_asset}</td>
              <td>{asset.kondisi}</td>
              <td>{formatDate2(asset.tanggal_perawatan)}</td>
              <td>{asset.nama_pic}</td>
              <td>{asset.detail_pic}</td>  {/* Displaying new column */}
              <td>{asset.status_asset}</td>
              <td>{asset.harga_perolehan}</td>
              <td>{asset.no_contract}</td>
              <td>{asset.keterangan}</td>
              <td>{asset.dokumen_ref}</td>
              <td>{asset.gedung}</td>
              <td>{asset.lantai}</td>
              <td>{asset.ruangan}</td>
              <td>{asset.username}</td>
              <td>{asset.approval_deletion_reason}</td>  {/* Displaying new column */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="button-container">
        <button className="modal-button edit" onClick={handleApprove}>
          Approve
        </button>
        <button className="modal-button delete" onClick={handleDeny}>
          Deny
        </button>
      </div>
    </div>
  );
};

export default ApprovalFetcher;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function ChangePassword({ user }) {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUserChangePassword = async (event) => {
    event.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = formData;

    if (!oldPassword || !newPassword || !confirmPassword) {
      alert("All password fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match");
      return;
    }

    try {
      const response = await fetch(
        `https://amipartnership.com/api/users/${user.username}/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      alert(data.message);
      setFormData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      navigate("/login"); // Redirect to login page after password change
    } catch (error) {
      console.error("Error changing password:", error);
      alert(`Failed to change password. Please try again. ${error.message}`);
    }
  };

  return (
    <div className="settings-view">
      <div className="header">
        <h3>Change Password</h3>
      </div>
      <form id="passwordChangeForm" onSubmit={handleUserChangePassword}>
        <div>
          <label htmlFor="oldPassword">Current Password:</label>
          <input
            type="password"
            id="oldPassword"
            name="oldPassword"
            value={formData.oldPassword}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <label htmlFor="confirmPassword">Confirm New Password:</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <br />
          <br />
          <button type="submit">Change Password</button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;

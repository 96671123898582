import React, { useState, useEffect } from "react";
import UserFetcher from "./UserFetcher";

function Admin_menu({ user }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvPreview, setCsvPreview] = useState([]);
  const [rfidData, setRfidData] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToEdit, setUserToEdit] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userFormData, setUserFormData] = useState({
    username: "",
    password: "",
    type: "",
  });

  useEffect(() => {
    fetchUserData();
    fetchRfidData();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n").map((row) => row.split(","));
      setCsvPreview(rows);
    };
    reader.onerror = (error) => {
      console.error("Error reading the file:", error);
    };
    reader.readAsText(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setCsvPreview([]);
    // fileInputRef.current.value = "";
  };

  const handleFileUpload = async () => {
    try {
      const rfidNumbers = csvPreview
        .slice(1) // Exclude header row
        .map((row) => row[0]) // Extract RFID numbers from CSV preview
        .filter((rfid) => rfid && rfid.trim() !== ""); // Filter out empty or invalid RFID numbers

      if (rfidNumbers.length === 0) {
        throw new Error("No valid RFID numbers found in the file");
      }

      const response = await fetch("https://amipartnership.com/api/allowed_rfid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rfid_numbers: rfidNumbers }),
      });

      if (!response.ok) {
        throw new Error("Failed to upload RFID numbers");
      }

      console.log("RFID numbers uploaded successfully");
      handleClearFile();
      fetchRfidData();
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };

  const fetchUserData = () => {
    fetch("https://amipartnership.com/api/users")
      .then((response) => response.json())
      .then((data) => setUserData(data.documents))
      .catch((err) => console.error(err));
  };

  const fetchRfidData = async () => {
    try {
      const response = await fetch(`https://amipartnership.com/api/data/${user.ID_User}`);
      if (!response.ok) {
        throw new Error(`HTTP response status: ${response.status}`);
      }
      const data = await response.json();
      if (!data || !data.data || data.data.trim() === "") {
        return [];
      }
      const rfidData = data.data.split(",").map((item) => item.trim());
      setRfidData(rfidData);
    } catch (err) {
      console.error("Error fetching RFID data:", err);
    }
  };

  const renderRfidData = () => {
    if (rfidData.length === 0) return <p>No RFID data available</p>;

    return (
      <ul>
        {rfidData.map((rfid, index) => (
          <li key={index}>
            {index + 1}. {rfid}
          </li>
        ))}
      </ul>
    );
  };

  const handleUserFormChange = (event) => {
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const handleUserFormSubmit = async (event) => {
    event.preventDefault();

    try {
      if (showAddUserModal) {
        const requestBody = JSON.stringify({
          username: userFormData.username,
          password: userFormData.password,
          type: userFormData.type,
        });

        const response = await fetch("https://amipartnership.com/api/users", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: requestBody,
        });

        if (!response.ok) {
          throw new Error("Failed to add user");
        }

        fetchUserData();
        closeModal();
      } else if (showEditUserModal) {
        const response = await fetch(
          `https://amipartnership.com/api/users/${userToEdit.ID_User}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userFormData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to edit user");
        }

        fetchUserData();
        closeModal();
        
      }
    } catch (error) {
      console.error("Error submitting user form:", error);
    }
  };

  const showModal = (modalType, user = null) => {
    if (modalType === "addUser") {
      setShowAddUserModal(true);
      setUserFormData({
        username: "",
        password: "",
        type: "",
      });
    } else if (modalType === "editUser") {
      setShowEditUserModal(true);
      setUserToEdit(user);
      setUserFormData({
        username: user.username,
        password: user.password,
        type: user.type,
      });
    }
  };

  const closeModal = () => {
    setShowAddUserModal(false);
    setShowEditUserModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUserRowClick = (user) => {
    setUserToEdit(user);
    showModal("editUser", user);
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(
        `https://amipartnership.com/api/users/${userId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      const updatedUserData = userData.filter((user) => user.ID_User !== userId);
      setUserData(updatedUserData);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleAddRfid = async () => {
    try {
      if (rfidData.length === 0) {
        console.error("No RFID numbers available.");
        return;
      }

      for (let i = 0; i < rfidData.length; i++) {
        const response = await fetch("https://amipartnership.com/api/allowed_rfid", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rfid_number: rfidData[i] }),
        });

        if (!response.ok) {
          throw new Error(`Failed to add RFID number ${rfidData[i]}`);
        }
      }

      console.log("All RFID numbers added successfully");
      alert("RFID Berhasil Ditambahkan.")
      fetchRfidData(); // Refresh RFID data after addition
    } catch (error) {
      console.error("Error adding RFID numbers:", error);
    }
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Admin Menu</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>List Users</h3>
              <div className="header-container">
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i
                  className="bx bx-plus"
                  onClick={() => showModal("addUser")}
                ></i>
              </div>
            </div>
            <UserFetcher
              returnType="table"
              searchQuery={searchQuery}
              onRowClick={handleUserRowClick}
              handleUserDelete={handleDelete}
            />
          </div>
        </div>

        
      </main>

      {/* Add User Modal */}
      <div className={`modal ${showAddUserModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Add User</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="addUser" onSubmit={handleUserFormSubmit}>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={userFormData.username}
                onChange={handleUserFormChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={userFormData.password}
                onChange={handleUserFormChange}
              />
              <select
                name="type"
                value={userFormData.type}
                onChange={handleUserFormChange}
              >
                <option value="">Type</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </form>
          </div>
          <div className="modal-content-footer">
            <div style={{ textAlign: "center" }}>
              <button
                className="modal-button edit"
                form="editUser"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit User Modal */}
      <div className={`modal ${showEditUserModal ? "open" : ""}`}>
        <div className="modal-content">
          <div className="modal-content-header">
            <h2>Edit User</h2>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-content-body">
            <form id="editUser" onSubmit={handleUserFormSubmit}>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={userFormData.username}
                onChange={handleUserFormChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={userFormData.password}
                onChange={handleUserFormChange}
              />
              <select
                name="type"
                value={userFormData.type}
                onChange={handleUserFormChange}
              >
                <option value="">Type</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </form>
          </div>
          <div className="modal-content-footer">
            <div style={{ textAlign: "center" }}>
              <button
                className="modal-button edit"
                form="editUser"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin_menu;

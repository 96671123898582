import React, { useState, useEffect } from "react";
import ExportExcel from "./ExportExcel";
import ApprovalFetcher from "./ApprovalFetcher";

function Approval_menu({ user }) {
  const [showAddRfidModal, setShowAddRfidModal] = useState(false);
  const [showEditRfidModal, setShowEditRfidModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState(""); // Added filter status
  const [rfidToEdit, setRfidToEdit] = useState(null);
  const [ApprovalData, setApprovalData] = useState([]);
  const [ApprovalFormData, setApprovalFormData] = useState({
    rfid_number: "",
    rfid_used_status: ""
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10); // 10 items per page

  useEffect(() => {
    fetchApproval();
  }, []);

  const fetchApproval = () => {
    fetch("https://amipartnership.com/api/approval")
      .then((response) => response.json())
      .then((data) => setApprovalData(data.documents))
      .catch((err) => console.error(err));
  };

  const showModal = (modalType, rfid = null) => {
    if (modalType === "addRfid") {
      setShowAddRfidModal(true);
      setApprovalFormData({
        rfid_number: "",
        rfid_used_status: ""
      });
    } else if (modalType === "editRfid") {
      setShowEditRfidModal(true);
      setRfidToEdit(rfid);
      setApprovalFormData({
        rfid_number: rfid.rfid_number,
        rfid_used_status: rfid.rfid_used_status
      });
    }
  };

  const closeModal = () => {
    setShowAddRfidModal(false);
    setShowEditRfidModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleStatusFilterChange = (event) => { // New handler for status filter
    setFilterStatus(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleRfidFormChange = (event) => {
    const { name, value } = event.target;
    setApprovalFormData({ ...ApprovalFormData, [name]: value });
  };

  const handleRfidRowClick = (rfid) => {
    setRfidToEdit(rfid);
    showModal("editRfid", rfid);
  };

  const handleRfidFormSubmit = (event) => {
    event.preventDefault();
    if (showAddRfidModal) {
      // Add rfid
      fetch("https://amipartnership.com/api/allowed_rfids", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ApprovalFormData)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchApproval(); // Refresh data after adding rfid
          closeModal();
        })
        .catch((error) => console.error("Error adding rfid:", error));
    } else if (showEditRfidModal) {
      // Edit rfid
      fetch(`https://amipartnership.com/api/allowed_rfids/${rfidToEdit.rfid_number}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ApprovalFormData)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          fetchApproval(); // Refresh data after editing rfid
          closeModal();
        })
        .catch((error) => console.error("Error editing rfid:", error));
    }
  };

  const handleRfidDelete = (rfidNumber) => {
    fetch(`https://amipartnership.com/api/approval/${rfidNumber}`, {
      method: "DELETE"
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const updatedRfidData = ApprovalData.filter((rfid) => rfid.rfid !== rfidNumber);
        setApprovalData(updatedRfidData);
        alert("RFID deleted successfully");
      })
      .catch((error) => console.error("Error deleting rfid:", error));
  };

  // Pagination logic
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
//   const currentRfidData = ApprovalData.slice(indexOfFirstItem, indexOfLastItem);
//   const totalPages = Math.ceil(ApprovalData.length / perPage);

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Approval Menu</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <ApprovalFetcher
            />
          </div>
        </div>

    
      </main>
      
    </div>
  );
}

export default Approval_menu;

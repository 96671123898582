import React, { useState, useEffect, useRef } from "react";
import DataFetcher from "./dataFetcher";

function Settings({ theme, setTheme, user }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isFetcher, setIsFetcher] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  
  const [csvPreview, setCsvPreview] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleRetrieveData = (e) => {
    e.preventDefault();
    setFetchData(true); // Trigger fetching data
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("logo", selectedFile);

    try {
      const response = await fetch("https://amipartnership.com/api/upload/logo", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("File uploaded successfully");
        setFetchData(true); // Trigger fetching data
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setPreviewImage(null);
    // Clear the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const renderCsvPreview = () => {
    if (csvPreview.length === 0) return null;

    // Extract headers and data rows
    const headers = csvPreview[0];
    const dataRows = csvPreview.slice(1);
    const previewRows = dataRows.slice(0, 5);
    const totalRows = dataRows.length;

    return (
      <div className="csv-preview">
        <h3>CSV Preview:</h3>
        <p>Total rows: {totalRows}</p>
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {previewRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Effect to apply theme-based styles to the body
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("dark", theme === "dark");
      body.classList.toggle("light", theme === "light");
    }
  }, [theme]);

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Settings</h1>
          </div>
        </div>

        <div className="bottom-data" style={{ flexDirection: "column" }}>
          <div className="settings-view">
            <div className="header">
              <h3>Appearance</h3>
            </div>
            <form action="#">
              <div className="theme-options">
                <div className="option-container">
                  <input
                    type="radio"
                    id="light-theme"
                    name="theme"
                    value="light"
                    checked={theme === "light"}
                    onChange={() => setTheme("light")}
                  />
                  <label htmlFor="light-theme">
                    Light theme <i className="bx bxs-sun"></i>
                  </label>
                </div>
                <div className="option-container">
                  <input
                    type="radio"
                    id="dark-theme"
                    name="theme"
                    value="dark"
                    checked={theme === "dark"}
                    onChange={() => setTheme("dark")}
                  />
                  <label htmlFor="dark-theme">
                    Dark theme <i className="bx bxs-moon"></i>
                  </label>
                </div>
              </div>
            </form>
          </div>

          {user.type === "admin" && (
            <div className="settings-view">
            <div className="header">
              <h3>Change Logo</h3>
            </div>
            <div className="change-logo-container">
              <div className="file-input-container">
                <div className="file-input">
                  <label htmlFor="logo">Upload logo:</label>
                  <input
                    type="file"
                    id="logo"
                    name="logo"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                </div>
                <div className="file-buttons">
                  <button onClick={handleClearFile}>Clear</button>
                  <button onClick={handleFileUpload} disabled={!selectedFile}>
                    Upload
                  </button>
                </div>
              </div>
              <div className="file-preview-container">
                {previewImage && (
                  <div className="file-preview">
                    <img src={previewImage} alt="Preview" />
                  </div>
                )}
              </div>
            </div>
          </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default Settings;

import React, { useState, useEffect } from "react";
import DBFetcher from "./databaseFetcher";
import CombinedFetcher from "./mergeFetcher";
import DBFetcherUser from "./databaseFetcherUser";

function ScanRoom({ user }) {
  const [targetItems, setTargetItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0); // Add a refreshKey state
  const [selectedOption, setSelectedOption] = useState("");
  const [missingAssets, setMissingAssets] = useState([]); // New state for missing assets

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCombinedDataFetch = (combinedRFIDs) => {
    setCurrentItems(combinedRFIDs);
    identifyMissingAssets(targetItems, combinedRFIDs); // Check for missing assets after fetching current items
  };

  const identifyMissingAssets = (targets, currents) => {
    const missing = targets.filter(target => !currents.includes(target));
    setMissingAssets(missing); // Update the state with missing assets
  };

  const refreshData = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    setTargetItems([]); // Optionally reset targetItems
    setCurrentItems([]); // Optionally reset currentItems
    setMissingAssets([]); // Reset missing assets
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Scan Room</h1>
          </div>
          <div className="right">
            <button className="refresh-button" onClick={refreshData}>
              Refresh <i className="bx bx-refresh"></i>
            </button>
          </div>
        </div>

        <div className="bottom-data">
          {/* First box */}
          <div className="database-view">
            <div className="header">
              <h3>Target Items</h3>
            </div>
            <DBFetcherUser
              key={`db-fetcher-${refreshKey}`} // Use refreshKey to force re-fetch
              returnType="table"
              onFetchTargetItems={setTargetItems}
              currentItems={currentItems}
              onRowClick={() => {}}
              user={user}
            />
          </div>

          {/* Second box */}
          <div className="database-view">
            <div className="header">
              <h3>Current Items</h3>
            </div>
            <CombinedFetcher
              key={`combined-fetcher-${refreshKey}`} // Use refreshKey to force re-fetch
              onFetchCombinedData={handleCombinedDataFetch}
              user={user}
            />
          </div>

          
        </div>
      </main>
    </div>
  );
}

export default ScanRoom;

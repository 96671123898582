import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";

function Sidebar({ handleLogout, user }) {
  const location = useLocation();

  useEffect(() => {
    const sideLinks = document.querySelectorAll(".sidebar .side-menu li a");
    sideLinks.forEach((link) => {
      const path = link.getAttribute("href");
      if (path === location.pathname) {
        link.parentElement.classList.add("active");
      } else {
        link.parentElement.classList.remove("active");
      }
    });
  }, [location]);

  console.log("User data received in side bar param:", user);

  return (
    <div className="sidebar">
      <Link to="/" className="logo" style={{background :"var(--light)", margin:"0 0 10px 0"}}>
        <div className="logo-name">
          <img src="logo.png" alt="Bank BJB Logo" />
        </div>
      </Link>
      <ul className="side-menu">
        <li>
          <Link to="/" className="nav-link">
            <i className="bx bx-data"></i>Database View
          </Link>
        </li>
        <li>
          <Link to="/maintenance" className="nav-link">
            <i className="bx bx-wrench"></i>Asset Maintenance
          </Link>
        </li>
        <li>
          <Link to="/scan-room" className="nav-link">
            <i className="bx bx-spreadsheet"></i>Scan Room
          </Link>
        </li>
          
        <li>
          <Link to="/rfid" className="nav-link">
            <i className="bx bx-rfid"></i>RFID Menu
          </Link>
        </li>
        <li>
          <Link to="/changes-history" className="nav-link">
            <i className="bx bx-history"></i>Change History
          </Link>
        </li><li>
          <Link to="/delete-history" className="nav-link">
            <i className="bx bx-history"></i>Delete History
          </Link>
        </li>
        {/* <li>
          <Link to="/content" className="nav-link">
            <i className="bx bx-mail-send"></i>Detail Content
          </Link>
        </li> */}
        
        {user && user.type === "admin" && (
          <li>
          <Link to="/admin_menu" className="nav-link">
            <i className="bx bx-user"></i>Admin Menu
          </Link>
        </li>
        )}
        {user && user.type === "admin" && (
          <li>
            <Link to="/approval_menu" className="nav-link">
              <i className="bx bx-check"></i>Approval Menu
            </Link>
          </li>
        )}
        <li>
          <Link to="/settings" className="nav-link">
            <i className="bx bx-cog"></i>Settings
          </Link>
        </li>
      </ul>

      <ul className="side-menu">
        <li>
          <a className="logout" onClick={handleLogout}>
            <i className="bx bx-log-out-circle" id="logoutButton"></i>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Login({ onLogin }) {
  const [isLoginView, setIsLoginView] = useState(true);
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    type: "",
  });
  const [formData, setFormData] = useState({
    username: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleFormDataChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://amipartnership.com/api/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          username: userInfo.username,
          password: userInfo.password,
          type: userInfo.type,
        }),
      });

      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem("user", JSON.stringify(data.user));
        onLogin(true, data.user);
        navigate("/");
      } else {  
        alert("Login failed. Please check your credentials.");
      }
    } catch (err) {
      console.error("Error during login:", err);
      alert("An error occurred while logging in.");
    }
  };

  const handleUserChangePassword = async (event) => {
    event.preventDefault();
    const { username, oldPassword, newPassword, confirmPassword } = formData;

    if (!username || !oldPassword || !newPassword || !confirmPassword) {
      alert("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match");
      return;
    }

    try {
      const response = await fetch(
        `https://amipartnership.com/api/users/${username}/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      alert(data.message);
      setFormData({
        username: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setIsLoginView(true); // Switch back to login view after password change
    } catch (error) {
      console.error("Error changing password:", error);
      alert(`Failed to change password. Please try again. ${error.message}`);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="logo-section">
          <div className="logo-header">
            <h2>Asset Management Information</h2>
          </div>
          <img src="logo.png" alt="Logo" />
          <div className="image-logo">
            
          </div>
          
        </div>
        <div className="login-section">
          {isLoginView ? (
            <>
              <h1>Login</h1>
              <form onSubmit={handleLogin}>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  autoComplete="off"
                  required
                  onChange={handleOnChange}
                />
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="off"
                  required
                  onChange={handleOnChange}
                />
                <button type="submit">Login</button>
              </form>
              <button onClick={() => setIsLoginView(false)}>
                Change Password
              </button>
            </>
          ) : (
            <>
              <div className="change-password-container">
                <h2>Change Password</h2>
                <form
                  id="passwordChangeForm"
                  onSubmit={handleUserChangePassword}
                >
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleFormDataChange}
                    required
                  />
                  <br />
                  <label htmlFor="oldPassword">Current Password:</label>
                  <input
                    type="password"
                    id="oldPassword"
                    name="oldPassword"
                    value={formData.oldPassword}
                    onChange={handleFormDataChange}
                    required
                  />
                  <br />
                  <label htmlFor="newPassword">New Password:</label>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleFormDataChange}
                    required
                  />
                  <br />
                  <label htmlFor="confirmPassword">Confirm New Password:</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleFormDataChange}
                    required
                  />
                  <br />
                  <button id="btn-change-password" type="submit">
                    Change Password
                  </button>
                </form>
                <button id="btn-back-login" onClick={() => setIsLoginView(true)}>
                  Back to Login
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;

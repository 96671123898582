import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./DBFetcher.css"; // Assuming this is your CSS file
import DocumentTable from "./DocumentTable";
import ExampleComponent from "./example";
import DocumentList from "./example";
import "./docFetcher.css";

const DocFetcher = ({
  onFetchTargetItems,
  currentItems = [],
  searchQuery = "",
  onRowClick,
}) => {
  const [data, setData] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetchData(); // Fetch data initially
  }, [onFetchTargetItems]);

  const fetchData = () => {
    fetch("https://amipartnership.com/api/documents")
      .then((response) => response.json())
      .then((data) => {
        setData(data.documents);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.documents.map((doc) => doc.rfid_number));
        }
      })
      .catch((err) => console.error(err));
  };

  const handleRefresh = () => {
    fetchData(); // Fetch data again when refresh button is clicked
  };

  if (!data.length) return "Loading...";

  // Normalize the currentItems for comparison
  const normalizedCurrentItems = currentItems.map((item) =>
    item.trim().toLowerCase()
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returns yyyy-mm-dd
  };

  // Filter data based on searchQuery
  const filteredData = data.filter((doc) => {
    const searchLowerCase = searchQuery.toLowerCase();

    // Function to check if a field contains the search query
    const containsSearchQuery = (field) =>
      field && field.toString().toLowerCase().includes(searchLowerCase);

    // Parse the JSON content field to extract the type value safely
    const parsedContent = doc.content ? JSON.parse(doc.content) : {};
    const tipeDokumen = parsedContent.type
      ? parsedContent.type.toLowerCase()
      : "";

    return (
      containsSearchQuery(doc.no_dokumen) ||
      containsSearchQuery(doc.nama_nasabah) ||
      containsSearchQuery(doc.instansi) ||
      containsSearchQuery(doc.kc) ||
      containsSearchQuery(doc.nomor_telepon_nasabah) ||
      containsSearchQuery(doc.alamat_nasabah) ||
      containsSearchQuery(doc.nik_nasabah) ||
      containsSearchQuery(doc.pekerjaan_nasabah) ||
      containsSearchQuery(doc.nomor_rekening_nasabah) ||
      containsSearchQuery(doc.nama_agunan) ||
      containsSearchQuery(doc.no_dokumen_agunan) ||
      containsSearchQuery(formatDate(doc.tanggal_akad)) ||
      containsSearchQuery(doc.lama_pinjaman) ||
      containsSearchQuery(doc.besar_pinjaman) ||
      containsSearchQuery(formatDate(doc.tanggal_jatuh_tempo)) ||
      containsSearchQuery(doc.jumlah_cicilan) ||
      containsSearchQuery(tipeDokumen) ||
      containsSearchQuery(doc.rfid_number) ||
      containsSearchQuery(doc.keterangan) ||
      containsSearchQuery(doc.room) ||
      containsSearchQuery(doc.row) ||
      containsSearchQuery(doc.rack) ||
      containsSearchQuery(doc.box)
    );
  });

  const handleSelectChange = (event) => {
    const selectedRfidNumber = event.target.value;
    onRowClick(selectedRfidNumber);
    const selectedDoc = data.find((doc) => doc.rfid_number === selectedRfidNumber);
    setSelectedDocument(selectedDoc);
  };

  const renderNestedContent = (content) => {
    if (!Array.isArray(content)) return null;
    return content.map((item, index) => (
      <div key={index} className="nested-content">
        <p><strong>{item.name}</strong>: {item.value || item.keterangan}</p>
        {item.children && item.children.length > 0 && (
          <div className="nested-children">
            {renderNestedContent(item.children)}
          </div>
        )}
        {item.subCategories && item.subCategories.length > 0 && (
          <div className="nested-subcategories">
            {renderNestedContent(item.subCategories)}
          </div>
        )}
      </div>
    ));
  };

  const transformContent = (content) => {
    if (!Array.isArray(content)) return []; // Ensure content is always an array to prevent map errors
  
    const normalizeChildren = (children) => {
      if (!children) return [];
      return children.map((child) => ({
        name: child.name,
        value: child.value || "",
        keterangan: child.keterangan || "",
        children: normalizeChildren(child.children),
      }));
    };
  
    const normalizeSubCategories = (subCategories) => {
      if (!subCategories) return [];
      return subCategories.map((subCategory) => ({
        name: subCategory.name,
        value: subCategory.value || "",
        keterangan: subCategory.keterangan || "",
        children: normalizeChildren(subCategory.children),
      }));
    };
  
    return content.map((item) => ({
      name: item.name,
      subCategories: normalizeSubCategories(item.subCategories),
    }));
  };
  // const contentData = selectedDocument && selectedDocument.content ? JSON.parse(selectedDocument.content).content : [];



  return (
    <div className="doc-fetcher-container">
      <div className="dropdown-container">
        <label htmlFor="documentDropdown">Select Document: </label>
        <select
          id="documentDropdown"
          onChange={handleSelectChange}
          defaultValue=""
        >
          <option value="" disabled>
            Choose a document
          </option>
          {filteredData.map((doc, index) => (
            <option key={index} value={doc.rfid_number}>
              {doc.no_dokumen} - {doc.nama_nasabah}
            </option>
          ))}
        </select>
      </div>
      {selectedDocument && (
        <div className="document-details">
        <h3>Document Details</h3>
        <p><strong>No Dokumen:</strong> {selectedDocument.no_dokumen}</p>
        <p><strong>Nama Nasabah:</strong> {selectedDocument.nama_nasabah}</p>
        
        
        <DocumentTable content={selectedDocument} no_dokumenP={selectedDocument.no_dokumen}/>    
      </div>
      
      )}
    </div>
  );
};

export default DocFetcher;

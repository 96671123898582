import React, { useEffect, useState } from "react";

// Moved getUserDataTable function outside of the component
const getUserDataTable = (data, searchQuery, onRowClick, handleUserDelete) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>No data available</div>;
  }

  const filteredData = data.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="table-container">
      <table className="user-table">
        <thead>
          <tr id="tb-header" style={{backgroundColor:"#abcaf5"}}>
            <th>ID</th>
            <th>Username</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((user) => (
            <tr key={user.ID_User} onClick={() => onRowClick(user)}>
              <td>{user.ID_User}</td>
              <td>{user.username}</td>
              <td>{user.type}</td>
              <td>
                <button
                  className="modal-button edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(user);
                  }}
                >
                  Edit
                </button>
                <button
                className="modal-button delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserDelete(user.ID_User);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UserFetcher = ({
  returnType,
  searchQuery,
  onRowClick,
  handleUserDelete,
}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://amipartnership.com/api/users");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUsers(data.users);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setContent(
      getUserDataTable(users, searchQuery, onRowClick, handleUserDelete)
    );
  }, [users, searchQuery, onRowClick, handleUserDelete]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>{content}</div>;
};

export default UserFetcher;

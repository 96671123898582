import React, { useEffect, useState } from 'react';
import './DBFetcher.css'; // Import CSS for styling

const CombinedFetcher = ({ onFetchCombinedData, user}) => {
  const [assetsData, setAssetsData] = useState([]);
  const [rfidData, setRfidData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await fetch('https://amipartnership.com/api/assets');
        if (!response.ok) {
          throw new Error(`HTTP response status: ${response.status}`);
        }
        const data = await response.json();
        const assets = data.assets.map(asset => ({
          id_asset: asset.id_asset || 'Unknown',
          no_rfid: asset.no_rfid || 'Unknown',
          no_inventaris: asset.no_inventaris || 'Unknown',
          kode_asset: asset.kode_asset || 'Unknown',
          nama_asset: asset.nama_asset || 'Unknown',
          kondisi: asset.kondisi || 'Unknown',
          tanggal_perawatan: asset.tanggal_perawatan || 'Unknown',
          nama_pic: asset.nama_pic || 'Unknown',
          detail_pic: asset.detail_pic || 'Unknown',
          status_asset: asset.status_asset || 'Unknown',
          harga_perolehan: asset.harga_perolehan || 'Unknown',
          no_contract: asset.no_contract || 'Unknown',
          keterangan: asset.keterangan || 'Unknown',
          // Include location fields if necessary
          gedung: asset.gedung || 'Unknown',
          lantai: asset.lantai || 'Unknown',
          ruangan: asset.ruangan || 'Unknown',
        }));
        return assets;
      } catch (err) {
        console.error('Error fetching assets:', err);
        throw new Error('Error fetching assets');
      }
    };

    const fetchRfidData = async () => {
      try {
        const response = await fetch(`https://amipartnership.com/api/data/${user.user_id}`);

        if (!response.ok) {
          throw new Error(`HTTP response status: ${response.status}`);
        }
        const data = await response.json();
        if (!data || !data.data || data.data.trim() === "") {
          return [];
        }
        const rfidData = data.data.split(',').map(item => item.trim());
        return rfidData;
      } catch (err) {
        console.error('Waiting for Scanner Data', err);
        throw new Error('Waiting for Scanner Data');
      }
    };

    Promise.all([fetchAssets(), fetchRfidData()])
      .then(([assets, rfidData]) => {
        setAssetsData(assets);
        setRfidData(rfidData);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Error fetching combined data:', err);
        setError(err.message);
        setIsLoading(false);
      });
  }, [user.ID_User]);

  useEffect(() => {
    if (assetsData.length > 0 && rfidData.length > 0) {
      const combined = rfidData.map(rfid => {
        const asset = assetsData.find(a => a.no_rfid === rfid);
        return {
          id_asset: asset ? asset.id_asset : 'Unknown',
          nama_asset: asset ? asset.nama_asset : 'Unknown',
          kondisi: asset ? asset.kondisi : 'Unknown',
          tanggal_perawatan: asset ? asset.tanggal_perawatan : 'Unknown',
          no_rfid: rfid,
        };
      });

      setCombinedData(combined);

      if (onFetchCombinedData) {
        const validCombinedData = combined.filter(item => item.id_asset !== 'Unknown');
        onFetchCombinedData(validCombinedData.map(item => item.no_rfid));
      }
    }
  }, [assetsData, rfidData, onFetchCombinedData]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="table-container">
      <table id="combinedTable" className="user-table">
        <thead>
          <tr>
            <th colSpan="4">Total Aset: {combinedData.length}</th>
          </tr>
          <tr id='tb-header' style={{backgroundColor:"#abcaf5"}}>
            <th>ID Aset</th>
            <th>Nama Aset</th>
            <th>Kondisi</th>
            <th>RFID Number</th>
          </tr>
        </thead>
        <tbody>
          {combinedData.map((item, index) => (
            <tr key={index}>
              <td>{item.id_asset}</td>
              <td>{item.nama_asset}</td>
              <td>{item.kondisi}</td>
              <td>{item.no_rfid}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CombinedFetcher;

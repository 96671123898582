import React from "react";
import DocumentTable from "./DocumentTable"; // Sesuaikan path import dengan struktur proyek Anda
import "./InputForm.css";
import DBFetcher from "./databaseFetcher";

const InputForm = () => {
  const content = [
    {
      "name": "Informasi Debitur",
      "subCategories": [
        {
          "name": "Formulir Permohonan Kredit beserta Lampirannya",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Formulir Permohonan Kredit",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Surat Kuasa Memotong/Mendebet Rekening",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Surat Pernyataan dan Rekomendasi",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan 1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan 2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan 3",
              "value": "",
              "keterangan": ""
            }
          ]
        },
        {
          "name": "2 (dua) lembar pas photo terbaru pemohon beserta suami/istri ukuran 3x4 cm.",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Tambahan 1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan 2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan 3",
              "value": "",
              "keterangan": ""
            }
          ]
        },
      ]
    },
    {
      "name": "Informasi Gaji Kepegawaian",
      "subCategories": [
        {
          "name": "Daftar gaji yang dibuat oleh bendahara gaji dan disetujui oleh atasan langsung.",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Tambahan1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan3",
              "value": "",
              "keterangan": ""
            }
          ]
        },
        {
          "name": "Copy Ledger gaji terakhir atau yang dipersamakan yang telah dilegalisir oleh bendahara gaji.",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Tambahan1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan3",
              "value": "",
              "keterangan": ""
            }
          ]
        },
        {
          "name": "Copy Tunjangan Daerah, Sertifikasi/Lainnya.",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Tambahan1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan3",
              "value": "",
              "keterangan": ""
            }
          ]
        },
        {
          "name": "Copy Rekening Koran.",
          "value": "",
          "keterangan": "",
          "children": [
            {
              "name": "Tambahan1",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan2",
              "value": "",
              "keterangan": ""
            },
            {
              "name": "Tambahan3",
              "value": "",
              "keterangan": ""
            }
          ]
        }
      ]
    }
  ];

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Detail Content</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <div className="header">
              <h3>Konsumer</h3>
              <div className="header-container">
                
              </div>
            </div>
            {/* Assuming RFIDFetcher component handles rendering of RFIDs */}
            <DocumentTable content={content} />
            {/* <DBFetcher />  */}
          </div>
        </div>

    
      </main>
      
    </div>
  );
};

export default InputForm;

import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";

const ExportExcel = () => {
  const handleExport = async () => {
    try {
      const response = await axios.get(
        "https://amipartnership.com/api/fulldocuments"
      );
      const data = response.data.documents;

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const currentDate = new Date();

      // Get individual components of the date
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are zero-indexed, so January is 0
      const day = currentDate.getDate();

      // Format date as YYYY-MM-DD
      const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
      XLSX.writeFile(workbook, `Database-${formattedDate}.xlsx`);

      console.log("Data exported successfully!");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
      <i className="bx bx-download" onClick={handleExport} style={{ marginLeft: '20px' }}></i>
  );
};

export default ExportExcel;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Sidebar from "./components/Sidebar";
import NavBar from "./components/NavBar";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import ScanRoom from "./components/ScanRoom";
import Settings from "./components/Settings";
import "./App.css";
import InputForm from "./components/InputForm";
import Admin_menu from "./components/Admin_menu";
import RFID_menu from "./components/RFID_menu";
import Approval_menu from "./components/Approval_menu";
import EditDocument from "./components/Edit_document";
import ChangePassword from "./components/ChangePassword";
import DocumentHistory from "./components/DocumentHistory";
import DocSelector from "./components/DocSelector";
import AssetMaintenance from "./components/AssetMaintenance";
import DeleteHistory from "./components/DeleteHistory";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [theme, setTheme] = useState("light"); // Default theme is light
  const [refreshSettings, setRefreshSettings] = useState(false);
  console.log("User data received in App:", user);

  // Effect to check login status on component mount
  useEffect(() => {
    const checkLogin = async () => {
      try {
        const response = await fetch("https://amipartnership.com/api/login", {
          method: "GET",
          credentials: "include",
        });

        if (response.status === 200) {
          const data = await response.json();
          setUser(data.user);
          console.log("User data:", data.user);

          setIsLoggedIn(true);
        } else {
          setUser(null);
          setIsLoggedIn(false); // Set to false if response is not successful
        }
      } catch (err) {
        console.error("Error checking login status:", err);
        setUser(null);
        setIsLoggedIn(false); // Set to false if an error occurs
      }
    };

    checkLogin();
  }, []); // Empty dependency array ensures this runs only once on mount

  // Function to handle login
  const handleLogin = (loggedIn, userData) => {
    setIsLoggedIn(loggedIn);
    setUser(userData);
    setRefreshSettings(true); // Set refresh flag on login
  };

  const handleLogout = async () => {
    try {
      const response = await fetch("https://amipartnership.com/api/logout", {
        method: "POST",
        credentials: "include",
      });

      if (response.status === 200) {
        console.log("Logout successful");
        setUser(null);
        setIsLoggedIn(false);
      } else {
        console.error("Logout failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  // Function to reset refresh flag after settings route load
  const handleSettingsLoad = () => {
    setRefreshSettings(false);
  };

  return (
    <Router>
      <div className={`app ${theme}`}>
        {/* Helmet for adding external CSS */}
        <Helmet>
          <link
            href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
            rel="stylesheet"
          />
        </Helmet>

        {/* Conditional rendering based on login state */}
        {isLoggedIn ? (
          <>
            <Sidebar handleLogout={handleLogout} user={user} />
            <NavBar theme={theme} setTheme={setTheme} />

            {/* Routes for different components */}
            <Routes>
              <Route path="/" element={<Dashboard user={user} />} />
              <Route path="/scan-room" element={<ScanRoom user={user} />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/input-form/:param" element={<InputForm />} />
              <Route path="/maintenance" element={<AssetMaintenance />} />
              <Route path="/rfid" element={<RFID_menu />} />
              <Route path="/content/" element={<InputForm />} />
              <Route path="/docSelector/" element={<DocSelector />} />
              <Route path="/changes-history" element={<DocumentHistory />} />
              <Route path="/delete-history" element={<DeleteHistory />} />
              <Route path="/admin_menu" element={<Admin_menu user={user} />} />
              <Route path="/approval_menu" element={<Approval_menu user={user}/>} />
              <Route
                path="/settings"
                element={
                  <Settings
                    theme={theme}
                    setTheme={setTheme}
                    user={user}
                    onFirstLoad={handleSettingsLoad} // Pass callback to Settings component
                  />
                }
              />
            </Routes>
          </>
        ) : (
          <Login onLogin={handleLogin} />
        )}

        {/* Conditional full refresh of settings route */}
        {refreshSettings && <RefreshRoute path="/" />}
      </div>
    </Router>
  );
}

// Component to trigger full page refresh for a specific route
const RefreshRoute = ({ path }) => {
  useEffect(() => {
    window.location.href = path; // Redirect to the route to trigger refresh
  }, [path]);

  return null;
};

export default App;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DataFetcher from "./dataFetcher";
import DBFetcher from "./databaseFetcher";
import ExportExcel from "./ExportExcel";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import HistoryFetcher from "./HistoryFecther";

function DocumentHistory({ user }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rfidSearch, setRfidSearch] = useState("");
  const [filteredRFIDs, setFilteredRFIDs] = useState([]);
  const [allRFIDs, setAllRFIDs] = useState([]);
  const [usedRFIDs, setUsedRFIDs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [rfidToEdit, setRfidToEdit] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    No_Dokumen: "",
    Nama_Nasabah: "",
    Instansi: "",
    KC: "",
    Nomor_Telepon_Nasabah: "",
    Alamat_Nasabah: "",
    NIK_Nasabah: "",
    Pekerjaan_Nasabah: "",
    Nomor_Rekening_Nasabah: "",
    Nama_Agunan: "",
    No_Dokumen_Agunan: "",
    Tanggal_Akad: "",
    Lama_Pinjaman: "",
    Besar_Pinjaman: "",
    Tanggal_Jatuh_Tempo: "",
    Jumlah_Cicilan: "",
    Content: "",
    Location_id: null,
    RFID_number: "",
    Keterangan: "",
  });

  const [locations, setLocations] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [shelves, setShelves] = useState([]);
  const [boxes, setBoxes] = useState([]);

  const location = useLocation(); // Access the current location
  
  useEffect(() => {
    fetch("https://amipartnership.com/api/allowed_rfids")
      .then((response) => response.json())
      .then((data) => setAllRFIDs(data.documents.map((doc) => doc.rfid)))
      .catch((err) => console.error(err));

    fetch("https://amipartnership.com/api/used_rfids")
      .then((response) => response.json())
      .then((data) => setUsedRFIDs(data.documents.map((doc) => doc.rfid)))
      .catch((err) => console.error(err));

    fetch("https://amipartnership.com/api/locations")
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
        const uniqueRooms = [...new Set(data.map((loc) => loc.room))];
        setRooms(uniqueRooms);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (formData.room) {
      const filteredRows = locations.filter(
        (loc) => loc.room === formData.room
      );
      const uniqueRows = [...new Set(filteredRows.map((loc) => loc.row))];
      setRows(uniqueRows);
      setRacks([]);
      setShelves([]);
      setBoxes([]);
      setFormData({ ...formData, row: "", rack: "", shelf: "", box: "" });
    }
  }, [formData.room]);

  useEffect(() => {
    if (formData.row) {
      const filteredRacks = locations.filter(
        (loc) => loc.room === formData.room && loc.row === formData.row
      );
      const uniqueRacks = [...new Set(filteredRacks.map((loc) => loc.rack))];
      setRacks(uniqueRacks);
      setShelves([]);
      setBoxes([]);
      setFormData({ ...formData, rack: "", shelf: "", box: "" });
    }
  }, [formData.row]);

  useEffect(() => {
    if (formData.rack) {
      const filteredShelves = locations.filter(
        (loc) =>
          loc.room === formData.room &&
          loc.row === formData.row &&
          loc.rack === formData.rack
      );
      const uniqueShelves = [
        ...new Set(filteredShelves.map((loc) => loc.shelf)),
      ];
      setShelves(uniqueShelves);
      setBoxes([]);
      setFormData({ ...formData, shelf: "", box: "" });
    }
  }, [formData.rack]);

  useEffect(() => {
    if (formData.shelf) {
      const filteredBoxes = locations.filter(
        (loc) =>
          loc.room === formData.room &&
          loc.row === formData.row &&
          loc.rack === formData.rack &&
          loc.shelf === formData.shelf
      );
      const uniqueBoxes = [...new Set(filteredBoxes.map((loc) => loc.box))];
      setBoxes(uniqueBoxes);
      setFormData({ ...formData, box: "" });
    }
  }, [formData.shelf]);

  const showModal = (modalType) => {
    if (modalType === "add") {
      setShowAddModal(true);
    } else if (modalType === "edit") {
      setShowEditModal(true);
    }
  };

  const closeModal = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    setFilteredRFIDs([]);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleRfidSearch = (event) => {
    const value = event.target.value;
    setRfidSearch(value);
    if (value) {
      setFilteredRFIDs(
        allRFIDs.filter((rfid) =>
          rfid.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredRFIDs([]);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRfidSelect = (rfid) => {
    setRfidSearch(rfid);
    setFormData({ ...formData, RFID_number: rfid });
    setFilteredRFIDs([]);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowClick = (rfid) => {
    console.log(`Row clicked with RFID: ${rfid}`);
    setRfidToEdit(rfid);
    showModal("edit");

    fetch(`https://amipartnership.com/api/documents/${rfid}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data fetched:", data);
        setFormData(data.document);
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //Handler Add Dokumen
  const handleFormSubmitAddDoc = (event) => {
    event.preventDefault();

    // Extract location details from formData
    const { room, row, rack, shelf, box } = formData;

    // Find matching location_id based on selected room, row, rack, shelf, box
    const selectedLocation = locations.find(
      (loc) =>
        loc.room === room &&
        loc.row === row &&
        loc.rack === rack &&
        loc.shelf === shelf &&
        loc.box === box
    );

    if (!selectedLocation) {
      console.error("Location not found!");
      return;
    }

    const { location_id } = selectedLocation;

    // Prepare document data for submission
    const {
      No_Dokumen,
      Nama_Nasabah,
      Instansi,
      KC,
      Nomor_Telepon_Nasabah,
      Alamat_Nasabah,
      NIK_Nasabah,
      Pekerjaan_Nasabah,
      Nomor_Rekening_Nasabah,
      Nama_Agunan,
      No_Dokumen_Agunan,
      Tanggal_Akad,
      Lama_Pinjaman,
      Besar_Pinjaman,
      Tanggal_Jatuh_Tempo,
      Jumlah_Cicilan,
      Content,
      RFID_number,
      Keterangan,
    } = formData;

    // Perform API request to submit the document data
    fetch("https://amipartnership.com/api/documents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        No_Dokumen,
        Nama_Nasabah,
        Instansi,
        KC,
        Nomor_Telepon_Nasabah,
        Alamat_Nasabah,
        NIK_Nasabah,
        Pekerjaan_Nasabah,
        Nomor_Rekening_Nasabah,
        Nama_Agunan,
        No_Dokumen_Agunan,
        Tanggal_Akad,
        Lama_Pinjaman,
        Besar_Pinjaman,
        Tanggal_Jatuh_Tempo,
        Jumlah_Cicilan,
        Content,
        Location_id: location_id,
        RFID_number,
        Keterangan,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Document created successfully:", data);
        // Optionally, perform any additional actions upon successful submission
        alert("Document created successfully");
        closeModal();
      })
      .catch((error) => {
        console.error("Error creating document:", error);
        // Handle errors appropriately, e.g., display an error message
      });
  };

  //Handler Edit Dokumen
  const handleFormSubmitUpdateDoc = (event) => {
    event.preventDefault();

    // Extract location details from formData
    const { room, row, rack, shelf, box } = formData;

    // Find matching location_id based on selected room, row, rack, shelf, box
    const selectedLocation = locations.find(
      (loc) =>
        loc.room === room &&
        loc.row === row &&
        loc.rack === rack &&
        loc.shelf === shelf &&
        loc.box === box
    );

    if (!selectedLocation) {
      console.error("Location not found!");
      return;
    }

    const { location_id } = selectedLocation;

    // Prepare document data for submission
    const {
      No_Dokumen,
      Nama_Nasabah,
      Instansi,
      KC,
      Nomor_Telepon_Nasabah,
      Alamat_Nasabah,
      NIK_Nasabah,
      Pekerjaan_Nasabah,
      Nomor_Rekening_Nasabah,
      Nama_Agunan,
      No_Dokumen_Agunan,
      Tanggal_Akad,
      Lama_Pinjaman,
      Besar_Pinjaman,
      Tanggal_Jatuh_Tempo,
      Jumlah_Cicilan,
      Content,
      RFID_number,
      Keterangan,
    } = formData;

    // Perform API request to update the document data
    fetch(`https://amipartnership.com/api/documents/${No_Dokumen}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        No_Dokumen,
        Nama_Nasabah,
        Instansi,
        KC,
        Nomor_Telepon_Nasabah,
        Alamat_Nasabah,
        NIK_Nasabah,
        Pekerjaan_Nasabah,
        Nomor_Rekening_Nasabah,
        Nama_Agunan,
        No_Dokumen_Agunan,
        Tanggal_Akad,
        Lama_Pinjaman,
        Besar_Pinjaman,
        Tanggal_Jatuh_Tempo,
        Jumlah_Cicilan,
        Content,
        Location_id: location_id,
        RFID_number,
        Keterangan,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Document updated successfully:", data);
        alert("Document updated successfully");
        closeModal(); // Example: Close modal after successful update
      })
      .catch((error) => {
        console.error("Error updating document:", error);
        alert("Error updating document");
        // Handle errors appropriately, e.g., display an error message
      });
  };

  const navigate = useNavigate(); // Access the history object
  //Handler Delete Dokumen
  const handleDeleteDocument = (document_id) => {
    // Confirm with the user before deleting, showing document_id in the message
    const confirmed = window.confirm(
      `Are you sure you want to delete document with ID ${document_id}?`
    );

    if (!confirmed) {
      return; // If user cancels, do nothing
    }

    // Perform API request to delete the document
    fetch(`https://amipartnership.com/api/documents/${document_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Document deleted successfully:", data);
        // Optionally, perform any additional actions upon successful deletion
      // Example: Close modal after successful deletion
      closeModal();
      navigate(location.pathname);// Redirect to dashboard after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
        // Handle errors appropriately, e.g., display an error message
      });
  };

  return (
    <div className="content">
      <main>
        <div className="header">
          <div className="left">
            <h1>Change History</h1>
          </div>
        </div>

        <div className="bottom-data">
          <div className="database-view">
            <HistoryFetcher
              returnType="table"
              searchQuery={searchQuery}
              onRowClick={handleRowClick}
            />
          </div>
        </div>
      </main>
      
    </div>
  );
}

export default DocumentHistory;